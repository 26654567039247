import React from 'react';
import { Table, Card, CardHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Note } from '../../../../assets/icons';
import { S3_BUCKET_URL } from '../../../../../environment';
import PropTypes from 'prop-types';
import CustomTooltip from '../../../../../shared/components/CustomTooltip';
import { commafy } from '../../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../../shared/utility/roundingNumber';
function OrderDetailViewTable({
  isMobile,
  // orderItem,
  onClickRow,
  currentIndex,
  items,
  orderPayableTotalCal,
}) {
  const { t } = useTranslation();
  const renderUnitPrice = product => {
    if (product?.newUpdate == null) {
      return '';
    } else {
      if (product?.newUpdate?.UnitPrice == null) {
        return 'N/A';
      } else {
        return commafy(RoundNum(product?.newUpdate?.UnitPrice, 2));
      }
    }
  };
  return (
    <Card>
      <CardHeader>
        <span className="header-row">
          <span className="header-title">{t('TAG_NUMBER')}</span>|
          <span className="header-child">{items[currentIndex]?.tagNo}</span>
        </span>
        <span className="header-row">
          <span className="header-title"> {t('BUYER_NAME')}</span>|
          <span className="header-child">
            {items[currentIndex]?.buyerCompany}
          </span>
        </span>
        <span className="header-row">
          <span className="header-title">{t('TOTAL_VALUE') + ' | '}</span>
          <span className="header-title">
            LKR {commafy(RoundNum(orderPayableTotalCal, 2))}
          </span>
        </span>
      </CardHeader>

      <Table style={{ marginBottom: '0', borderRadius: '25px' }} hover>
        {!isMobile && (
          <>
            <thead className="table-header">
              <tr>
                <th className="title table-border"></th>
                <th className="title">{t('PRODUCT_NAME')}</th>
                <th className="title">{t('QUANTITY')}</th>
                <th className="title">{t('UNIT_PRICE')}</th>
                <th className="title text-center">{t('NOTE')}</th>
                <th className="title">{t('STATUS')}</th>
              </tr>
            </thead>
          </>
        )}
        <tbody>
          {items[currentIndex]?.orderItems?.map((product, index) => (
            <tr
              className="table-row cursor-pointer"
              key={index}
              style={{
                backgroundColor:
                  product?.productStatus === 'In Progress'
                    ? 'white'
                    : product?.productStatus === 'Ready' &&
                      product?.purchaseOrderProductStatusForMp === 5
                    ? 'white'
                    : '#F5FFFA',
                opacity:
                  product?.purchaseOrderProductStatusForMp === 5 ? 0.7 : 1,
                pointerEvents:
                  product?.purchaseOrderProductStatusForMp === 5
                    ? 'none'
                    : 'auto',
                cursor:
                  product?.purchaseOrderProductStatusForMp === 5
                    ? 'not-allowed'
                    : 'pointer',
              }}
              onClick={e => {
                if (product?.purchaseOrderProductStatusForMp === 5) return;

                if (e.target.id.includes('DisabledAutoHideNote')) {
                  return;
                }
                onClickRow(product);
              }}
            >
              {isMobile ? (
                <>
                  <td className="row-img table-border">
                    <img
                      className="product-img"
                      src={S3_BUCKET_URL + 'Products/' + product?.productImage}
                    />
                  </td>
                  <td className="table-data-1">
                    <small className="d-block">{product?.productName}</small>
                    <small className="d-block warning">
                      Qty: {product?.quantity} {product?.unitMeasure}
                    </small>
                  </td>

                  <td className="table-data-2">
                    <small style={{ color: '#383e92' }} className="d-block">
                      S: {product?.productStatus}
                    </small>
                    <small style={{ color: '#11121e' }} className="d-block">
                      P:{''}
                      {renderUnitPrice(product)}
                    </small>
                  </td>
                  <td width="50px" className="table-data text-center">
                    {product?.note?.length > 0 ? (
                      <>
                        <img
                          width="15"
                          className="notes-img"
                          src={Note}
                          alt="notes"
                          id={`DisabledAutoHideNote-order-detail-mp-${index}-${product.purchaseOrderProductId}`}
                        />
                        <CustomTooltip
                          id={`DisabledAutoHideNote-${index}-order-detail-procurement-detail`}
                          placement="left"
                          targetId={`DisabledAutoHideNote-order-detail-mp-${index}-${product.purchaseOrderProductId}`}
                          displayMessage={product?.note}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                </>
              ) : (
                <>
                  <td className="row-img table-border">
                    <img
                      className="product-img"
                      src={S3_BUCKET_URL + 'Products/' + product?.productImage}
                    />
                  </td>
                  <td className="table-data-1">{product?.productName}</td>
                  <td className="table-data-2">
                    {product?.quantity} {product?.unitMeasure}
                  </td>
                  <td className="table-data-3">{renderUnitPrice(product)}</td>
                  <td
                    width="50px"
                    id="mp-order-detail-note"
                    className="table-data text-center"
                  >
                    {product?.note?.length > 0 ? (
                      <>
                        <img
                          width="15"
                          className="notes-img"
                          src={Note}
                          alt="notes"
                          id={`DisabledAutoHideNote-order-detail-mp-${index}-${product.purchaseOrderProductId}`}
                        />
                        <CustomTooltip
                          id={`DisabledAutoHideNote-${index}-order-detail-procurement-detail`}
                          placement="left"
                          targetId={`DisabledAutoHideNote-order-detail-mp-${index}-${product.purchaseOrderProductId}`}
                          displayMessage={product?.note}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{product?.productStatus}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}

OrderDetailViewTable.propTypes = {
  currentIndex: PropTypes.any,
  isMobile: PropTypes.bool,
  // orderItem: PropTypes.any,
  onClickRow: PropTypes.func,
  inProgressItems: PropTypes.array,

  orderPayableTotalCal: PropTypes.any,
  items: PropTypes.any,
};

export default OrderDetailViewTable;
