import React, { useContext, useEffect, useState } from 'react';
import { Card, Input, Button, Col, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { BuyerContext } from '../../../../apis/buyer';
import { S3_BUCKET_URL } from '../../../../environment';
import moment from 'moment';
import { useDeviceDetect } from '../../../../shared/hooks';
import { imageNotFound } from '../../../assets/img/index';
import { t } from 'i18next';
import { commafy } from '../../../../shared/utility/commafyValue';
import { RoundNum } from '../../../../shared/utility/roundingNumber';
function ProductCard({
  product,
  selectedDate,
  selectedDeliverySlot,
  cartProductItems,
  setCartProductItems,
}) {
  const buyerContext = useContext(BuyerContext);
  const [inputValue, setInputValue] = useState(1);
  const [isAvilableInCart, setIsAvilableInCart] = useState(false);
  const [isQtySameInCart, setIsQtySameInCart] = useState(false);
  // const [cartProductItems, setCartProductItems] = useState(false);
  const {
    addProductToCart,
    updateProductCartItem,
    getCartProductItems,
  } = buyerContext;
  const { isMobile } = useDeviceDetect();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInputValue, setModalInputValue] = useState(inputValue);

  useEffect(() => {
    setInputValue(product.minimumQuantity);
    getQuantityFromCart(cartProductItems);
    checkAvalabilityInCart(cartProductItems);
  }, [product]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setModalInputValue(inputValue);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    onInputChange({ target: { value: modalInputValue } });
  };

  function isMultiple(value, base, scale = 100) {
    // Scale both numbers
    const scaledValue = Math.round(value * scale);
    const scaledBase = Math.round(base * scale);

    // Avoid division by zero
    if (scaledBase === 0) return NaN;

    return scaledValue % scaledBase === 0
      ? 0
      : (scaledValue % scaledBase) / scale;
  }

  const handleGettinggetCartProductItems = () => {
    getCartProductItems().then(res => {
      if (res.err) {
        return;
      } else {
        setCartProductItems(res.res);
        getQuantityFromCart(res.res);
        checkAvalabilityInCart(res.res);
      }
    });
  };

  //for futher development (editable text)
  // const onInputChange = e => {
  //   const decimalRegex = /^\d+(\.\d)?\d*$/;
  //   if (decimalRegex.test(e.target.value) || e.target.value.slice(-1) === '.') {
  //     setInputValue(e.target.value);
  //     setTimeout(function() {
  //       const regex = /^\d*\.?\d?$/;
  //       if (
  //         regex.test(e.target.value) &&
  //         Number(e.target.value) > product.minimumQuantity &&
  //         Number(e.target.value) < product.maximumQuantity
  //       ) {
  //         const number = parseFloat(e.target.value);
  //         const item = {
  //           CartId: cartProductItems.cartId,
  //           PurchasedQuntity: number,
  //           ExpectedDeliverySlotId: selectedDeliverySlot.id,
  //           ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
  //         };
  //         handleUpdateProductCartItem(item, number);
  //       } else {
  //         handleGettinggetCartProductItems();
  //         setError(
  //           'Item quantity must be in the range minimum of ' +
  //             product.minimumQuantity +
  //             ' and maximum of ' +
  //             product.maximumQuantity +
  //             ' range',
  //         );
  //         setTimeout(function() {
  //           setError(null);
  //         }, 3000);
  //       }
  //     }, 2000);
  //   }
  // };
  const onInputChange = e => {
    if (/^\d*\.?\d*$/.test(e.target.value)) {
      setInputValue(e.target.value);
      setIsQtySameInCart(false);
    }
  };
  const handleUpdateProductCartItem = (data, number) => {
    updateProductCartItem(product.id, data).then(res => {
      if (res.err) {
        return;
      } else {
        setInputValue(number);
        handleGettinggetCartProductItems();
      }
    });
  };

  const handleCart = () => {
    const item = {
      ProductId: product.id,
      PurchasedQuntity: inputValue ? inputValue : product.minimumQuantity,
      PurchasedPrice: parseFloat(product.averagePrice),
      ExpectedDeliverySlotId: selectedDeliverySlot.id,
      ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
    };
    addProductToCart(item).then(res => {
      if (res.err) {
        return;
      } else {
        handleGettinggetCartProductItems();
      }
    });
  };
  const handleValueRoundOff = () => {
    const isMultipleResult = isMultiple(inputValue, product.minimumQuantity);
    if (isMultipleResult != 0) {
      if (isMultipleResult < product.minimumQuantity) {
        const roundOffMinVal =
          Math.floor(inputValue / product.minimumQuantity) *
          product.minimumQuantity;
        setInputValue(
          roundOffMinVal <= 0 ? product.minimumQuantity : roundOffMinVal,
        );
      } else if (isMultipleResult > product.minimumQuantity) {
        const roundOffMaxVal =
          (Math.floor(inputValue / product.minimumQuantity) + 1) *
          product.minimumQuantity;
        setInputValue(
          roundOffMaxVal > product.maximumQuantity
            ? product.maximumQuantity
            : roundOffMaxVal,
        );
      }
    }
  };
  const handleConfirm = () => {
    const isMultipleResult = isMultiple(inputValue, product.minimumQuantity);
    if (isMultipleResult == 0 && inputValue != 0) {
      if (isAvilableInCart) {
        const item = {
          CartId: cartProductItems.cartId,
          PurchasedQuntity: parseFloat(inputValue),
          ExpectedDeliverySlotId: selectedDeliverySlot.id,
          ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
        };
        handleUpdateProductCartItem(item, inputValue);
      } else {
        setInputValue(inputValue);
        setModalInputValue(inputValue);
      }
    }
  };

  const handleValueDecrease = () => {
    const isMultipleResult = isMultiple(inputValue, product.minimumQuantity);
    if (inputValue >= product.minimumQuantity * 2 && isMultipleResult == 0) {
      if (isAvilableInCart) {
        const item = {
          CartId: cartProductItems.cartId,
          PurchasedQuntity: inputValue - product.minimumQuantity,
          ExpectedDeliverySlotId: selectedDeliverySlot.id,
          ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
        };
        handleUpdateProductCartItem(item, inputValue - product.minimumQuantity);
      } else {
        setInputValue(inputValue);
        setModalInputValue(inputValue);
      }
    }
  };

  const handleValueIncrease = () => {
    const isMultipleResult = isMultiple(inputValue, product.minimumQuantity);
    if (product.maximumQuantity) {
      if (
        inputValue <= product.maximumQuantity - product.minimumQuantity &&
        isMultipleResult == 0
      ) {
        if (isAvilableInCart) {
          const item = {
            CartId: cartProductItems.cartId,
            PurchasedQuntity: parseFloat(inputValue) + product.minimumQuantity,
            ExpectedDeliverySlotId: selectedDeliverySlot.id,
            ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
          };
          handleUpdateProductCartItem(
            item,
            inputValue + product.minimumQuantity,
          );
        } else {
          setInputValue(inputValue);
          setModalInputValue(inputValue);
        }
      }
    } else {
      if (isMultipleResult == 0) {
        if (isAvilableInCart) {
          const item = {
            CartId: cartProductItems.cartId,
            PurchasedQuntity: parseFloat(inputValue) + product.minimumQuantity,
            ExpectedDeliverySlotId: selectedDeliverySlot.id,
            ExpectedDeliveryDate: moment(selectedDate).format('YYYY-MM-DD'),
          };
          handleUpdateProductCartItem(
            item,
            inputValue + product.minimumQuantity,
          );
        } else {
          setInputValue(inputValue);
          setModalInputValue(inputValue);
        }
      }
    }
  };

  const checkAvalabilityInCart = items => {
    items.cartItems &&
      items.cartItems.forEach(ele => {
        if (Number(ele.productId) === Number(product.id)) {
          setIsAvilableInCart(true);
        }
      });
  };

  const getQuantityFromCart = items => {
    items.cartItems &&
      items.cartItems.forEach(ele => {
        if (ele.productId === product.id) {
          setInputValue(ele.quantity);
          setIsQtySameInCart(true);
        }
      });
  };

  return (
    <>
      {product && (
        <Card className="product-card flex-grow-1 flex-sm-grow-0">
          <div
            className={`${isMobile ? 'product-name-mobile' : 'product-name'}`}
            title={product.productName}
          >
            {product.productName}
          </div>
          <div className="d-flex flex-sm-column justify-content-between">
            <div className="d-flex flex-sm-column sm-w-100 product-details">
              <div className="product-card-img-container">
                <img
                  loading="lazy"
                  className={`${
                    isMobile ? 'product-card-img-mobile' : 'product-card-img'
                  }`}
                  src={
                    product.productImage
                      ? S3_BUCKET_URL + 'Products/' + product.productImage
                      : imageNotFound
                  }
                  alt="Image Not Found"
                />
              </div>
              <div
                className="product-info mt-0 mt-sm-2"
                style={{ verticalAlign: 'middle' }}
              >
                <div className="price-container">
                  <p className="product-price-label">
                    {isMobile ? 'Avg. P' : 'Avg. Price'}
                  </p>
                  <p className="product-price-value">
                    {product.averagePrice
                      ? 'LKR ' +
                        commafy(
                          RoundNum(
                            Math.round(product.averagePrice * 100) / 100,
                            2,
                          ),
                        ) +
                        '/' +
                        product.unitType
                      : 'Pending/' + product.unitType}
                  </p>
                </div>
              </div>
            </div>

            <Col>
              <div className="product-item-new-action-row">
                {isAvilableInCart && (
                  <>
                    <div
                      className={`product-value-roundoff ${
                        !product.maximumQuantity
                          ? isMultiple(inputValue, product.minimumQuantity) != 0
                            ? ''
                            : 'roundoff-disabled'
                          : isMultiple(inputValue, product.minimumQuantity) !=
                              0 ||
                            inputValue <=
                              product.maximumQuantity - product.minimumQuantity
                          ? ''
                          : 'roundoff-disabled'
                      }`}
                      onClick={handleValueRoundOff}
                    >
                      Round
                    </div>
                    <div
                      className={`product-value-confirm ${
                        !product.maximumQuantity
                          ? isMultiple(inputValue, product.minimumQuantity) ==
                              0 && !isQtySameInCart
                            ? ''
                            : 'confirm-disabled'
                          : (isMultiple(inputValue, product.minimumQuantity) ==
                              0 ||
                              inputValue <=
                                product.maximumQuantity -
                                  product.minimumQuantity) &&
                            !isQtySameInCart
                          ? ''
                          : 'confirm-disabled'
                      }`}
                      onClick={handleConfirm}
                    >
                      Confirm
                    </div>
                  </>
                )}
              </div>
              <div className="product-item-action-row ">
                {isAvilableInCart && (
                  <>
                    <div
                      className={`product-value-decrease ${
                        inputValue >= product.minimumQuantity * 2 &&
                        isMultiple(inputValue, product.minimumQuantity) == 0
                          ? ''
                          : 'decrease-disabled'
                      }`}
                      onClick={handleValueDecrease}
                    >
                      -
                    </div>
                    <div className="product-value-input-container">
                      <Input
                        className="product-value-input"
                        onChange={e => onInputChange(e)}
                        value={inputValue}
                        onClick={handleModalOpen}
                        readOnly
                      />
                    </div>
                    <div
                      className={`product-value-increase ${
                        !product.maximumQuantity
                          ? isMultiple(inputValue, product.minimumQuantity) == 0
                            ? ''
                            : 'increase-disabled'
                          : inputValue <=
                              product.maximumQuantity -
                                product.minimumQuantity &&
                            isMultiple(inputValue, product.minimumQuantity) == 0
                          ? ''
                          : 'increase-disabled'
                      }`}
                      onClick={handleValueIncrease}
                    >
                      +
                    </div>
                  </>
                )}
                {!isAvilableInCart && (
                  <Button
                    color="primary"
                    type="submit"
                    className="form-submit add-to-cart-btn"
                    onClick={handleCart}
                  >
                    {t('ADD_TO_CART')}
                  </Button>
                )}
              </div>
              <Modal isOpen={isModalOpen} toggle={handleModalClose} centered>
                <div
                  className="modal-content"
                  style={{
                    borderRadius: '20px',
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
                    border: 'none',
                  }}
                >
                  <div
                    className="modal-header"
                    style={{
                      backgroundColor: '#d4edda', // Light green color (similar to your image)
                      color: '#1E3A8A',
                      padding: '24px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: '18px',
                      fontWeight: '600',
                    }}
                  >
                    <h5 style={{ margin: 0 }}>Enter Quantity</h5>
                    <button
                      type="button"
                      onClick={handleModalClose}
                      style={{
                        background: 'none',
                        border: 'none',
                        color: '#000',
                        fontSize: '22px',
                        cursor: 'pointer',
                      }}
                    >
                      &times;
                    </button>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      padding: '30px',
                      backgroundColor: '#f8f9fa',
                    }}
                  >
                    <Input
                      type="number"
                      value={modalInputValue}
                      onChange={e => setModalInputValue(e.target.value)}
                      style={{
                        width: '100%',
                        padding: '14px',
                        fontSize: '16px',
                        borderRadius: '10px',
                        border: '1px solid #ced4da',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        outline: 'none',
                      }}
                    />
                  </div>
                  <div
                    className="modal-footer"
                    style={{
                      padding: '20px 30px',
                      backgroundColor: '#fff',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '15px',
                    }}
                  >
                    <button
                      onClick={() => {
                        handleModalClose();
                        onInputChange({ target: { value: modalInputValue } });
                      }}
                      style={{
                        backgroundColor: '#ff7f50',
                        color: '#fff',
                        padding: '12px 24px',
                        borderRadius: '10px',
                        border: 'none',
                        fontWeight: '500',
                        flex: 1,
                        textAlign: 'center',
                        transition: 'background-color 0.3s',
                      }}
                      onMouseEnter={e =>
                        (e.target.style.backgroundColor = '#e56739')
                      }
                      onMouseLeave={e =>
                        (e.target.style.backgroundColor = '#ff7f50')
                      }
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
            </Col>
          </div>
        </Card>
      )}
    </>
  );
}
ProductCard.propTypes = {
  product: PropTypes.object,
  selectedDate: PropTypes.any,
  selectedDeliverySlot: PropTypes.any,
  cartProductItems: PropTypes.object,
  setCartProductItems: PropTypes.func,
};

export default ProductCard;
